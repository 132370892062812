nav {
    position: relative;
    height: 100px;
    padding: 0% 3%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav-logo {
    height: 60%;
    cursor: pointer;
}

.nav-logo img {
    height: 100%;
}

.nav-links {
    width: 550px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 11px;
    position: relative;
}

.nav-links a {
    position: relative;
    text-decoration: none;
    color: rgba(0, 0, 0, 0.7);
    font-weight: 400;
}

.nav-links a:hover {
    color: #4B9ABD;
}

.destination-button-cover {
    height: 55px;
    position: absolute;
    width: 102px;
    background-color: transparent;
    top: 18px;
    z-index: 1;
    cursor: pointer;
}

.nav-button {
    background-color: #1C5981;
    color: #FFFFFF !important;
    padding: 8px 22px;
}

.user-icon {
    height: 40px;
    width: 40px;
    border-radius: 50%;
}

.user-icon img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
}

.nav-underline {
    height: 1px;
    width: 94%;
    background-color: #D9D9D9;
    margin: auto;
}

.nav-hamburger {
    display: none;
    height: 35%;
    padding-top: 9px;
    padding-right: 3px;
    cursor: pointer;
}

.nav-hamburger img {
    height: 100%;
}

.alt-nav {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
    background-color: #FFFFFF;
    padding: 0% 3%;
    height: 0;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: 0.2s;
}

.alt-nav-image {
    height: 34px;
}

.alt-nav-image img {
    height: 100%;
}

.alt-nav-links {
    width: 400px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.alt-nav-links a {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}

.alt-nav-links a img {
    height: 20px;
    margin-right: 10px;
}

.alt-nav-links a span {
    color: black;
}

.alt-nav-contact {
    display: flex;
    justify-content: space-between;
    width: 65%;
    align-items: center;
}

.alt-nav-contact-separator {
    width: 1px;
    height: 30px;
    background-color: black;
}

.alt-nav-button {
    background-color: #1C5981;
    color: white !important;
    padding: 8px 22px;
}

.alt-user-icon {
    height: 30px !important;
    width: 30px !important;
    border-radius: 50%;
}

.alt-user-icon img {
    height: 100% !important;
    width: 100% !important;
    border-radius: 50%;
}

@media (max-width: 768px) {
    nav {
        height: 85px;
        padding: 0% 5%;
        padding-bottom: 5px;
        position: fixed;
        width: 100%;
        background-color: #F5F5F5;
        z-index: 99;
    }
    .nav-links {
        display: none;
    }
    .nav-underline {
        width: 100%;
        position: fixed;
        top: 85px;
    }
    .nav-hamburger {
        display: block;
    }
}