.profile-booking {
    width: 80%;
    padding: 5%;
    height: calc(100vh - 100px);
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.profile-booking::-webkit-scrollbar {
    display: none;
}

.profile-booking-head {
    background-color: #4B9ABD;
    padding: max(3%, 20px);
    border-radius: 20px;
    color: white;
    margin-bottom: 5vh;
}

.profile-booking-location {
    display: flex;
    align-items: center;
}

.profile-booking-location p {
    font-weight: 600;
    font-size: 1.6rem;
}

.profile-booking-location img {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    margin-right: 10px;
}

.profile-booking-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.profile-booking-details div {
    width: 50%;
}

.profile-booking-details p {
    font-weight: 400;
    margin-top: 10px;
    color: rgba(255, 255, 255, 0.8);
}

.profile-booking-details p a {
    color: rgba(255, 255, 255, 0.8);
}

.profile-booking-details p a i {
    font-size: 0.8rem;
}

.profile-booking-box {
    background-color: white;
    padding: max(3%, 20px);
    border-radius: 20px;
    margin-bottom: 5vh;
    border: 1px solid #4B9ABD;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.profile-booking-box::-webkit-scrollbar {
    display: none;
}

.profile-booking-box h3 {
    font-weight: 600;
    font-size: 1.6rem;
    margin-bottom: 15px;
}

.profile-booking-box table {
    width: 100%;
    border-collapse: collapse;
}

.profile-booking-box table tr {
    border-bottom: 1px solid gainsboro;
}

.profile-booking-box table tr td {
    padding: 10px;
}

.profile-booking-box table tr td b {
    font-weight: 600;
}

.profile-payment-button {
    background-color: #4B9ABD;
    color: white;
    padding: 10px 30px;
    border-radius: 10px;
    font-weight: 600;
    font-size: 1.2rem;
    margin-top: 20px;
    border: none;
    cursor: pointer;
}

.profile-green {
    color: green;
}

.profile-red {
    color: red;
}

.profile-payment-terms {
    color: rgba(0, 0, 0, 0.5);
    margin-top: 20px;
    font-size: 0.8rem;
}

.profile-booking-text {
    color: rgba(0, 0, 0, 0.5);
    margin-bottom: 20px;
}

.profile-cancellation-button {
    background-color: rgba(255, 0, 0, 0.8);
    color: white;
    padding: 10px 30px;
    border-radius: 10px;
    font-weight: 600;
    font-size: 1.2rem;
    border: none;
    cursor: pointer;
}

@media (max-width: 768px) {
    .profile-booking {
        width: 100%;
        padding-top: 100px;
        height: auto;
    }
    .profile-booking-details {
        flex-direction: column;
    }
    .profile-booking-details div {
        width: 100%;
    }
}