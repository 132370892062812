.contact {
    display: flex;
    justify-content: space-between;
}

.contact-left {
    width: 40%;
    background-color: #1C5981;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3%;
    box-shadow: 6.6173px 0px 7.94076px rgba(0, 0, 0, 0.15);
}

.contact-left h1 {
    color: white;
    text-transform: uppercase;
    font-size: 2em;
    font-weight: 600;
}

.contact-left img {
    width: 70%;
}

.contact-details {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.contact-details a {
    text-decoration: none;
    color: white;
}

.contact-detail {
    display: flex;
    color: white;
    margin: 10px;
}

.contact-detail i {
    font-size: 1.2em;
    margin-right: 10px;
}

.contact-detail p {
    font-size: 1.2em;
}

.contact-socials {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact-socials h2 {
    color: white;
    font-size: 1.5em;
    font-weight: 600;
    margin-bottom: 10px;
    margin-top: 20px;
}

.contact-social-icons {
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-social-icons i {
    font-size: 1.5em;
    margin: 10px;
    color: white;
}

.contact-right {
    width: 60%;
    padding: 3% 10%;
}

.contact-right h1 {
    font-size: 2em;
    font-weight: 600;
    margin-bottom: 5px;
}

.contact-right h3 {
    color: #909090;
    font-size: 1.2em;
    font-weight: 600;
}

.contact-form {
    margin-top: 20px;
}

.contact-form-input {
    width: 100%;
    margin: 10px 0;
}

.contact-form-input p {
    font-size: 1em;
    font-weight: 400;
    margin-bottom: 5px;
    color: rgba(0, 0, 0, 0.7);
}

.contact-form-input input {
    width: 100%;
    height: 40px;
    border: 1px solid #4B9ABD;
    border-radius: 5px;
    padding: 0 10px;
    font-size: 1em;
    font-weight: 400;
}

.contact-form-input textarea {
    width: 100%;
    height: 100px;
    border: 1px solid #4B9ABD;
    border-radius: 5px;
    padding: 10px;
    font-size: 1em;
    font-weight: 400;
    resize: none;
}

.contact-form-input:nth-child(5) {
    display: flex;
    align-items: center;
    font-size: 0.9em;
}

.contact-form-input:nth-child(5) p a {
    color: #4B9ABD;
    text-decoration: none;
}

#contact-terms {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.contact-form-input input:focus, .contact-form-input textarea:focus {
    outline: 1px solid #4B9ABD;
}

.contact-form button {
    width: 100%;
    height: 40px;
    border: none;
    border-radius: 5px;
    background-color: #4B9ABD;
    color: white;
    font-size: 1em;
    font-weight: 600;
    margin-top: 10px;
    cursor: pointer;
}

.error-input {
    border: 1px solid red;
}

.error-input:focus {
    outline: 1px solid red;
}

#contact-form-error {
    color: red;
    font-size: 0.9em;
    margin-bottom: 10px;
}

@media (max-width: 768px) {
    .contact {
        flex-direction: column;
    }
    .contact-left {
        width: 100%;
        padding-top: 120px;
        padding-bottom: 50px;
    }
    .contact-right {
        width: 100%;
        padding-top: 50px;
        padding-bottom: 50px;
    }
}