.stat-bar {
    background-color: #F3B758;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1% 3%;
    margin-top: 30px;
    margin-bottom: 30px;
}

.stat-bar-item {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.stat-bar-item-number {
    font-weight: 600;
    font-size: 1.2em;
    margin-right: 10px;
}

.stat-bar-item-text {
    font-weight: 400;
    font-size: 0.8em;
}

.stat-bar-separator {
    height: 20px;
    width: 1px;
    background-color: white;
    margin-left: 30px;
    margin-right: 30px;
}

@media (max-width: 768px) {
    .stat-bar {
        flex-direction: column;
        padding: 5% 0;
    }
    .stat-bar-separator {
        height: 1px;
        width: 100%;
        margin-top: 20px;
        margin-bottom: 20px;
    }
}