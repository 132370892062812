.destination-header {
    position: relative;
    height: max(40vh, 400px);
}

.destination-header-image {
    width: 100%;
    height: 100%;
}

.destination-header-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.destination-image-overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    position: absolute;
    top: 0;
    left: 0;
}

.destination-details {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.destination-details h1 {
    color: white;
    font-size: 2.4em;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.15em;
}

.destination-details h2 {
    color: rgba(255, 255, 255, 0.8);
    font-size: 1.2em;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    margin-top: 10px;
    font-style: italic;
}

@media (max-width: 768px) {
    .destination-header {
        padding-top: 85px;
        height: 60vh;
    }
    .destination-image-overlay {
        top: 85px;
        height: calc(100% - 85px);
    }
    .destination-details {
        top: calc(50% + 42.5px);
    }
}