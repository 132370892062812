.booking-cards {
    width: 80%;
    padding: 5%;
    height: calc(100vh - 100px);
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.booking-cards::-webkit-scrollbar {
    display: none;
}

.booking-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    padding: max(3%, 20px);
    border-radius: 20px;
    border: 1px solid #4B9ABD;
    margin-bottom: 3vh;
}

.booking-details {
    width: 35%;
}

.booking-location {
    display: flex;
    align-items: center;
}

.booking-location p {
    font-weight: 600;
    font-size: 1.4rem;
}

.booking-location img {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    margin-right: 10px;
}

.booking-text {
    font-size: 1rem;
    font-weight: 400;
    margin-top: 10px;
    color: rgba(0, 0, 0, 0.7);
}

.bookings-status {
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: 600;
}

.booking-card button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 10px;
    background-color: #4B9ABD;
    color: white;
    font-weight: 600;
    font-size: 1rem;
    padding: 12px 24px;
    cursor: pointer;
}

.green {
    color: green;
}

.red {
    color: red;
}

.orange {
    color: orange;
}

@media (max-width: 768px) {
    .booking-cards {
        padding-top: 100px;
        width: 100%;
        height: auto;
    }
    .booking-card {
        flex-direction: column;
        align-items: flex-start;
        height: auto;
    }
    .booking-details {
        width: 100%;
    }
    .bookings-status {
        width: 100%;
        margin-top: 2vh;
        margin-bottom: 2vh;
        display: block;
    }
}