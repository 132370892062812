.past-collaborations {
    background-color: #97836D;
    padding: 0 5%;
    justify-content: space-between;
    align-items: flex-start;
    /* height: 70vh; */
}

.past-collaborations-info {
    padding: 5% 0 3% 0;
}

.past-collaborations-info h2 {
    color: white;
    font-weight: 500;
    font-size: 1.8em;
    margin-bottom: 20px;
}

.past-collaborations-heading-underline {
    height: 3px;
    width: 10%;
    margin-top: 10px;
    background-color: white;
}

.past-collaborations-info p {
    color: white;
    width: 60%;
    line-height: 1.5em;
}

.past-collaborations-cards {
    display: flex;
    justify-content: space-around;
    align-items: center;
    overflow: hidden;
    padding-bottom: 5%;
}

.past-collaboration-card-control {
    display: flex;
    align-items: center;
    font-size: 2em;
    color: white;
    font-weight: 400;
}

.past-collaboration-card-control i {
    cursor: pointer;
}

.past-collaboration-cards-inner {
    display: flex;
    width: 80%;
    align-items: center;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    scroll-behavior: smooth;
}

.past-collaboration-cards-inner::-webkit-scrollbar {
    display: none;
}

.past-collaboration-card {
    height: 400px;
    width: 300px;
    min-width: 300px;
    background-color: white;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    margin-left: 1.5vw;
    margin-right: 1.5vw;
}

.past-collaboration-card-quote {
    width: 25%;
    margin-bottom: 20px;
}

.past-collaboration-card-quote img {
    width: 100%;
}

.past-collaboration-card-text {
    width: 90%;
    font-size: 0.75em;
    text-align: justify;
    margin-bottom: 20px;
    height: 35%;
    overflow: hidden;
}

.past-collaboration-card-author {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 25%;
}

.past-collaboration-card-author img {
    height: 100%;
    border-radius: 50%;
    margin-bottom: 10px;
}

.past-collaboration-card-author h5 {
    font-size: 0.9em;
    font-weight: 500;
    margin-bottom: 5px;
}

.past-collaboration-card-author p {
    font-size: 0.8em;
    color: #97836D;
}

@media (max-width: 768px) {
    .past-collaborations-heading-underline {
        width: 40%;
    }
    .past-collaborations-info p {
        width: 100%;
    }
    .past-collaboration-card-control {
        display: none;
    }
    .past-collaboration-cards-inner {
        padding: 5% 0;
        width: 100%;
    }
    .past-collaboration-card {
        margin-left: 0;
        margin-right: 3vw;
    }
}