.seller-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3% 5%;
}

.seller-head-image {
    width: max(30%, 300px);
}

.seller-head-image img {
    width: 100%;
}

.seller-head-info {
    width: 65%;
    background-color: white;
    padding: 4.5% 5%;
    border-bottom: 3px solid #10405D;
}

.seller-head-info h1 {
    font-size: 2em;
    font-weight: 700;
}

.seller-head-info h1 span {
    color: #10405D;
    text-transform: uppercase;
}

.seller-head-info h3 {
    color: rgba(0, 0, 0, 0.7);
    font-weight: 500;
    font-size: 1em;
    margin-top: 10px;
}

.seller-head-info h3 span {
    font-weight: 700;
}

.seller-head-info button {
    margin-top: 20px;
    font-weight: 700;
    color: white;
    font-size: 1.5em;
    background-color: #10405D;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
}

.seller-why {
    padding: max(3%, 20px) 5%;
    padding-bottom: 0;
}

.seller-why h2 {
    text-align: center;
    font-size: 1.6em;
}

.seller-why h2 span {
    text-transform: uppercase;
    color: #10405D;
}

.seller-why-cards {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: max(3%, 30px) 0;
}

.seller-why-card {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: max(320px, 35vw);
    margin-bottom: 40px;
}

.seller-why-card-image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: max(80px, 8vw);
    height: max(80px, 8vw);
    background-color: white;
    border: 1px solid #10405D;
    border-radius: 50%;
}

.seller-why-card-image img {
    width: 60%;
    height: 60%;
}

.seller-why-card-info {
    width: max(220px, 25vw);
}

.seller-why-card-info h3 {
    font-weight: 600;
    font-size: 1.4em;
    margin-bottom: 10px;
}

.seller-why-card-info p {
    color: #222222;
    line-height: 160%;
    text-align: justify;
}

.seller-onboarding {
    padding: max(3%, 20px) 0;
    padding-top: 0;
}

.seller-onboarding h2 {
    text-align: center;
    font-size: 1.6em;
}

.seller-onboarding h2 span {
    text-transform: uppercase;
    color: #10405D;
}

.seller-onboarding-steps {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-color: white;
    padding: 3% 5%;
    margin-top: 40px;
}

.seller-onboarding-step {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 20%;
    margin: 0 20px;
}

.seller-onboarding-step-image {
    width: 30%;
}

.seller-onboarding-step-image img {
    width: 100%;
}

.seller-onboarding-step-content {
    text-align: center;
}

.seller-onboarding-step-content h3 {
    font-weight: 600;
    font-size: 1.2em;
    margin-top: 10px;
}

.seller-onboarding-step-content p {
    color: rgba(0, 0, 0, 0.8);
    font-size: 1em;
    margin-top: 20px;
}

.seller-testimonials {
    padding: max(3%, 20px) 5%;
}

.seller-testimonials h3 {
    text-align: center;
    font-size: 1.6em;
}

.seller-testimonials h3 span {
    text-transform: uppercase;
    color: #10405D;
}

.seller-testimonials-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3%;
}

.seller-testimonials-button {
    width: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.seller-testimonials-button i {
    height: 40px;
    width: 40px;
    background-color: #4B9ABD;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 1.5em;
    border-radius: 50%;
    cursor: pointer;
}

.seller-testimonials-card {
    width: 65%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.seller-testimonail-content {
    text-align: justify;
    margin-bottom: 20px;
    color: #222222;
    line-height: 160%;
}

.seller-testimonial-details {
    text-align: center;
}

.seller-testimonial-details p {
    font-weight: 600;
    margin-bottom: 5px;
}

.seller-testimonials-nav {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 30px;
}

.seller-testimonials-nav i {
    margin: 0 5px;
    font-size: 0.8em;
    color: #D9D9D9;
}

.seller-testimonials-selected {
    color: #FFC727 !important;
    font-size: 1em !important;
}


.seller-join {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: max(3%, 20px) max(5%, 30px);
    margin-bottom: 10px;
}

.seller-join h3 {
    font-weight: 700;
    font-size: 2em;
    text-align: center;
}

.seller-join h3 span {
    color: #10405D;
}

.seller-join button {
    margin-top: 20px;
    font-weight: 700;
    color: white;
    font-size: 1.5em;
    background-color: #10405D;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
}

.seller-faqs {
    padding: max(3%, 20px) 5%;
}

.seller-faqs h3 {
    font-weight: 600;
    font-size: 1.4em;
}

.seller-faq-cards {
    padding: 20px 0;
}

.seller-faq-card {
    background-color: white;
    border-bottom: 2px solid #10405D;
    margin-bottom: 20px;
}

.seller-faq-card-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    font-size: 1.2em;
    font-weight: 500;
    cursor: pointer;
}

.seller-faq-card-answer {
    height: 0;
    overflow: hidden;
    padding: 0 15px;
    color: #222222;
}

.seller-form-modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
}

.seller-form {
    position: relative;
    width: max(50%, 600px);
    background-color: white;
    padding: 3%;
    border-radius: 5px;
}

.seller-form-close {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
}

.seller-form h3 {
    font-weight: 700;
    font-size: 1.4em;
    margin-bottom: 20px;
    text-align: center;
    color: #10405D;
}

.seller-form-input-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.seller-form-input {
    width: 48%;
}

.seller-form-input p {
    font-size: 0.8em;
    color: #222222;
    margin-bottom: 5px;
}

.seller-form-input input {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #4B9ABD;
    border-radius: 5px;
    font-size: 1em;
}

.seller-form-input input:focus {
    outline: 1px solid #4B9ABD;
}

.seller-w-100 {
    width: 100%;
}

.seller-form button {
    background-color: #10405D;
    color: white;
    font-weight: 700;
    font-size: 1em;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    margin-top: 20px;
    width: 100%;
    cursor: pointer;
}

#seller-form-error {
    color: red;
    font-weight: 300;
    font-size: 0.9em;
    padding-top: 5px;
}

.seller-form-success-image {
    width: 50%;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
}

.seller-form-success-image img {
    width: 90%;
}

.seller-success-msg {
    text-align: center;
    font-style: italic;
    color: rgba(0, 0, 0, 0.7);
}

@media (max-width: 768px) {
    .seller-head {
        flex-direction: column;
        padding-top: 100px;
    }
    .seller-head-image {
        display: none;
    }
    .seller-head-info {
        width: 100%;
        order: 1;
    }
    .seller-onboarding {
        padding: max(3%, 20px) 5%;
    }
    .seller-onboarding-steps {
        flex-direction: column;
    }
    .seller-onboarding-step {
        width: 100%;
        margin: 20px 0;
    }
    .seller-join {
        margin: max(3%, 20px) 5%;
    }
    .seller-testimonials-button {
        display: none;
    }
    .seller-testimonials-card {
        width: 100%;
    }
    .seller-form {
        width: 100%;
        position: absolute;
        bottom: 0;
        border-radius: 5px 5px 0 0;
        padding: 5%;
    }
    .seller-form h3 {
        width: 80%;
        margin: auto;
        margin-bottom: 20px;
    }
}