.travconnect {
    padding: 1% 3% 3% 3%;
}

.travconnect h2 {
    text-align: center;
    font-weight: 600;
    font-size: 1.8em;
}

.travconnect h2 span {
    color: #4B9ABD;
}

.travconnet-timeline {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: auto;
    margin-top: 40px;
    width: 90%;
}

.travconnect-timeline-item {
    text-align: center;
    width: 20%;
}

.travconnect-timeline-item i {
    margin-bottom: 10px;
    color: #4B9ABD;
}

.travconnect-timeline-item p {
    margin: auto;
    text-align: center;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.6);
}

.travconnect-timeline-line {
    position: absolute;
    border: 1px dashed #4B9ABD;
    height: 1px;
    width: 80%;
    top: 0.5em;
    left: 10%;
    z-index: -1;
}

.travconnect-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
    padding: 0 4%;
}

.travconnect-details-text {
    width: 50%;
}

.travconnect-details-text p {
    font-weight: 500;
}

.travconnect-details-text button {
    margin-top: 20px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #4B9ABD;
    color: #fff;
    font-size: 1.2em;
    font-weight: 500;
    cursor: pointer;
}

.travconnect-details-image {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.travconnect-details-image img {
    width: 50%;
}

.travconnect-cards {
    display: flex;
    overflow: scroll;
    margin-top: 40px;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.travconnect-cards::-webkit-scrollbar {
    display: none;
}

@media (max-width: 768px) {
    .travconnet-timeline {
        flex-direction: column;
        width: 90%;
    }
    .travconnect-timeline-item {
        width: 100%;
        display: flex;
        align-items: center;
        text-align: left;
        margin-bottom: 30px;
    }
    .travconnect-timeline-item i {
        margin-bottom: 0;
        margin-right: 10px;
        font-size: 1.2em;
    }
    .travconnect-timeline-item p {
        text-align: left;
        margin: 0;
    }
    .travconnect-timeline-line {
        width: 0;
        height: 80%;
        top: 1px;
        left: 0.5em;
    }
    .travconnect-details {
        margin-top: 0;
        flex-direction: column;
    }
    .travconnect-details-text {
        width: 100%;
        order: 2;
    }
    .travconnect-details-image {
        width: 100%;
        order: 1;
    }
    .travconnect-details-image img {
        width: 100%;
    }
}