.mobile-nav {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    overflow: hidden;
    width: 0;
    background-color: white; 
    transition: 0.5s;   
    z-index: 1000;
}

.mobile-nav-screen-cover {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    background-color: transparent;
    z-index: 999;
}

.mobile-nav-header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0% 3%;
    height: 86px;
    padding-bottom: 5px;
    border-bottom: 1px solid #D9D9D9;
}

.mobile-nav-header img {
    height: 55px;
}

.mobile-nav-links {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5% 10%;
}

.mobile-nav-links a, .mobile-nav-dest-click {
    text-decoration: none;
    color: black;
    font-weight: 400;
    font-size: 1.2em;
    padding-top: 2.5vh;
    padding-bottom: 2.5vh;
    border-bottom: 1px solid #000;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.mobile-nav-links a i, .mobile-nav-dest-click i {
    font-size: 0.8em;
    color: rgba(0, 0, 0, 0.5);
}

.mobile-nav-auth {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 5% 10%;
}

.mobile-nav-auth a {
    background-color: #1C5981;
    color: white;
    text-decoration: none;
    padding: 10px 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2vh;
    cursor: pointer;
}

.mobile-nav-invert-button {
    background-color: white !important;
    color: #1C5981 !important;
    border: 1px solid #1C5981 !important;
}

.mobile-nav-contact {
    position: absolute;
    bottom: 5vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.mobile-nav-contact a {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: rgba(0, 0, 0, 0.5);
    margin-top: 3vh;
}

.mobile-nav-contact img {
    height: 20px;
    margin-right: 5px;
}

.mobile-nav-destinations {
    padding: 0 10%;
    padding-bottom: 15vh;
    height: 90%;
    overflow: scroll;
    scrollbar-width: none;
}

.mobile-nav-destinations::-webkit-scrollbar {
    display: none;
}

.mobile-nav-destinations-header h3 {
    font-weight: 500;
    font-size: 1.4em;
    margin-top: 4vh;
}

.mobile-nav-destinations-header-underline {
    width: 100%;
    height: 2px;
    background-color: #4B9ABD;
    margin-top: 15px;
    margin-bottom: 10px;
}

.mobile-nav-destinations-list {
    display: flex;
    flex-direction: column;
}

.mobile-nav-destinations-list a {
    text-decoration: none;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: 400;
    color: black;
    width: max-content;
}

.mobile-nav-destinations-go-back {
    display: flex;
    padding-left: 10px;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #D9D9D9;
    cursor: pointer;
}

.mobile-nav-destinations-go-back i {
    font-size: 0.8em;
    margin-right: 5px;
    color: rgba(0, 0, 0, 0.5);
    padding-top: 1px;
}