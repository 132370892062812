.itinerary-nav {
    padding: max(4%, 25px) 5% 1% 5%;
}

.itinerary-nav ul {
    list-style: none;
    display: flex;
    align-items: flex-start;
}

.itinerary-nav ul li {
    text-transform: uppercase;
    font-weight: 600;
    margin-right: 5vw;
    font-size: 1.2rem;
    cursor: pointer;
}

.itinerary-selected {
    height: 3px;
    width: 70%;
    background-color: #4B9ABD;
    margin-top: 5px;
    cursor: default;
}

.itinerary {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 2% 5%;
}

.itinerary-left {
    width: 60%;
}

.itinerary-left h3 {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 1.2rem;
    margin-bottom: 2vh;
}

.itinerary-left h3 img {
    height: 20px;
    margin-left: 10px;
}

.dates-for-booking {
    margin-bottom: 3vh;
}

.date-cards {
    display: flex;
    align-items: center;
    margin-bottom: 2vh;
    flex-wrap: wrap;
}

.date-card {
    background-color: #1C5981;
    padding: 10px 15px;
    border-radius: 10px;
    margin-right: 10px;
    color: white;
    margin-bottom: 2vh;
    margin-right: 2vw;
    cursor: pointer;
}

.date-view-more {
    border: 1px solid #1C5981;
    padding: 10px 15px;
    border-radius: 10px;
    margin-right: 10px;
    color: #1C5981;
    margin-bottom: 2vh;
    margin-right: 2vw;
    cursor: pointer;
} 

.days-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 85%;
    margin-bottom: 4vh;
}

.days-nav-inner {
    display: flex;
    align-items: center;
    overflow-x: scroll;
    width: 90%;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding-right: 10px;
    padding-bottom: 1px;
}

.days-nav-inner::-webkit-scrollbar {
    display: none;
}

.date-nav-navigator {
    background-color: #4B9ABD;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: bold;
    font-size: 1.2rem;
    cursor: pointer;
}

.day-item {
    min-width: max-content;
    text-align: center;
    padding: 10px 15px;
    border-radius: 10px;
    margin-right: 10px;
    color: black;
    border: 1px solid #4B9ABD;
    margin-right: 2vw;
    cursor: pointer;
}

.selected-day {
    background-color: #4B9ABD;
    color: white;
}

.timeline-items {
    position: relative;
}

.timeline-item {
    width: 80%;
    margin-left: 2%;
}

.timeline-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    margin: auto;
}

.timeline-header-item {
    display: flex;
    align-items: center;
    font-weight: 600;
}

.timeline-index {
    height: 15px;
    width: 15px;
    background-color: #4B9ABD;
    border-radius: 50%;
    margin-right: 2vw;
}

.timeline-line {
    position: absolute;
    top: 10px;
    left: calc(6% + 6.4px);
    height: calc(100% - 10px);
    border: 1px dashed #4B9ABD;
    z-index: -1;
}

.timeline-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: max(2%, 20px) 5%;
    border: 1px solid #4B9ABD;
    border-radius: 10px;
    background-color: white;
    margin-top: 2vh;
    margin-bottom: 5vh;
}

.timeline-image {
    width: max(25%, 200px);
    height: 120px;
    margin-right: 5%;
}

.timeline-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.timeline-details {
    width: 80%;
}

.timeline-box h5 {
    font-weight: 500;
    font-size: 1.2rem;
    margin-bottom: 7px;
}

.width-100 {
    width: 100% !important;
}

.itinerary-right {
    width: 40%;
    top: 10vh;
}

.itinerary-price {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #1C5981;
    padding: 3% 5%;
    border-radius: 15px;
    margin-bottom: 2vh;
}

.itinerary-price-item {
    display: flex;
    align-items: baseline;
}

.itinerary-price-item h2 {
    color: white;
    font-size: 2rem;
}

.itinerary-price-item p {
    color: white;
    font-size: 0.8rem;
    margin-left: 10px;
    font-weight: 300;
}

.itinerary-book button {
    background-color: #4B9ABD;
    color: white;
    padding: 10px 20px;
    border-radius: 10px;
    border: none;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
}

.itinerary-component {
    margin-bottom: 5vh;
}

.itinerary-component h3 {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 1.2rem;
    margin-bottom: 2vh;
}

.itinerary-component h3 img {
    height: 20px;
    margin-left: 10px;
}

.offer-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.offer-details img {
    width: 30px;
    margin-right: 10px;
}

.offer-details p {
    font-size: 1rem;
    font-weight: 400;
}

.offer-details i {
    font-size: 1.2rem;
    cursor: pointer;
}

.offer-details-main {
    display: flex;
    align-items: center;
    width: 90%;
}

.offer-card {
    border-bottom: 2px solid #DDDDDD;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.offer-terms {
    width: 84%;
    margin: auto;
    height: 0;
    overflow: hidden;
}

.offer-terms h6 {
    font-weight: 500;
    font-size: 1rem;
    margin-bottom: 1vh;
    margin-top: 1vh;
}

.offer-terms li {
    color: rgba(0, 0, 0, 0.6);
}

.service-provider {
    text-transform: uppercase;
    font-weight: 700;
    color: white;
    background-color: #1C5981;
    width: max-content;
    padding: 10px 20px;
    border-radius: 10px;
    font-size: 1rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.assistance {
    margin-bottom: 3vh;
    font-weight: 500;
    background-color: #CCE1F9;
    border-radius: 10px;
    padding: 2% 4%;
    line-height: 150%;
}

.assistance pre {
    overflow-x: auto;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
}

.assistance a {
    color: #4B9ABD;
}

.inclusion-exlusion-box {
    border: 1px solid #4B9ABD;
    background-color: white;
    padding: 3% 5%;
    border-radius: 10px;
    margin-bottom: 20px;
}

.inclusion-exclusion-box-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.inclusion-exclusion-box-inner p {
    font-weight: 500;
}

.inclusion-exclusion-box-inner p span {
    font-weight: 600;
}

.inclusion-exclusion-details {
    width: 90%;
    margin: auto;
    height: 100%;
}

.inclusion-exclusion-details ul li {
    font-weight: 400;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 0.9em;
}



.other-packages {
    padding: 0 5% 2% 5%;
}

.other-packages h3 {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 1.2rem;
    margin-bottom: 2vh;
}

.other-packages h3 img {
    height: 20px;
    margin-left: 10px;
}

.packages-cards {
    display: flex;
    margin-top: 3vh;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.packages-cards::-webkit-scrollbar {
    display: none;
}

.packages-cards .trip-card {
    margin-left: 0 !important;
    margin-right: 30px !important;
}

@media (max-width: 768px) {
    .packages-cards .trip-card {
        min-width: min(320px, 100%) !important;;
        height: 420px !important;
    }
}

.reviews {
    padding: 2% 5%;
}

.reviews h3 {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 1.2rem;
    margin-bottom: 2vh;
}

.reviews h3 img {
    height: 20px;
    margin-left: 10px;
}

.reviews-container {
    display: flex;  
}

.reviews-stats {
    width: 35%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.reviews-overview {
    background: linear-gradient(215deg, #FFFFFF 0%, #E9E9E9 71.8%);
    box-shadow: -7.64769px 10.922px 26.6667px rgba(51, 51, 51, 0.114), inset 1.76747px -2.5242px 3.08148px #E3E3E3, inset -1.76747px 2.5242px 3.08148px #FFFFFF;
    border-radius: 50%;
    padding: 2%;
    width: 12vw;
    height: 12vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.reviews-overview-inner {
    background: #0487DA;
    box-shadow: 7.18459e-16px 11.7333px 45px rgba(1, 27, 44, 0.205), inset -2.07555e-16px -3.38963px 3.38963px #036FB4, inset 2.07555e-16px 3.38963px 3.38963px #059AF9;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    padding: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.reviews-overview-inner-inner {
    background: linear-gradient(215deg, #FFFFFF 0%, #E9E9E9 71.8%);
    box-shadow: -7.64769px 10.922px 26.6667px rgba(51, 51, 51, 0.114), inset 1.76747px -2.5242px 3.08148px #E3E3E3, inset -1.76747px 2.5242px 3.08148px #FFFFFF;
    border-radius: 50%;
    padding: 2%;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.reviews-rating {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
}

.reviews-rating img {
    height: 30px;
    margin-right: 5px;
}

.reviews-rating p {
    font-weight: 500;
    padding-top: 2.5px;
    font-size: 1.6rem;
    margin: 0;
}

.reviews-count {
    color: rgba(0, 0, 0, 0.8);
    font-size: 0.8rem;
}

.reviews-stats-bars {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2vh;
}

.reviews-stat-bar {
    display: flex;
    align-items: center;
    width: 90%;
    margin-bottom: 10px;
}

.reviews-stat-bar-head {
    display: flex;
    align-items: center;
    justify-content: right;
    width: 20px;
}

.reviews-stat-bar-head p {
    font-size: 1rem;
    font-weight: 500;
    margin-right: 3px;
    padding-top: 2px;
}

.reviews-stat-bar-head img {
    height: 20px;
}

.reviews-stat-bar-body {
    width: 100%;
    height: 10px;
    background: linear-gradient(180deg, #BCBCBC 0%, #DADADA 100%);
    box-shadow: inset 1.33768e-16px 2.18459px 2.18459px rgba(184, 184, 184, 0.5), inset -1.33768e-16px -2.18459px 2.18459px rgba(222, 222, 222, 0.5);
    border-radius: 5px;
    margin-left: 10px;
    margin-right: 10px;
}

.reviews-stat-bar-body-inner {
    height: 100%;
    background: #0487DA;
    border-radius: 5px;
}

.reviews-cards {
    width: 65%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-height: 40vh;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.reviews-cards::-webkit-scrollbar {
    display: none;
}

.review-card {
    width: 48%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background-color: white;
    border: 2px solid #1C5981;
    padding: max(2%, 15px);
    height: 50%;
    overflow: scroll;
    border-radius: 20px;
    margin-bottom: 10px;
    margin-right: 10px;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.review-card::-webkit-scrollbar {
    display: none;
}

.review-card-left {
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.review-card-left img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
}

.review-card-rating {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2vh;
}

.review-card-rating p {
    color: #1C5981;
    font-weight: 600;
    font-size: 1.2rem;
    padding-top: 3px;
}

.review-card-rating img {
    height: 25px;
    width: 25px;
    margin-left: 5px;
}

.reviews-card-right {
    width: 75%;
}

.review-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.review-card-details h6 {
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.9);
    font-weight: 600;
}

.review-card-details p {
    font-size: 0.8rem;
    color: rgba(0, 0, 0, 0.6);
    font-weight: 500;
}

.review-card-votes {
    display: flex;
    justify-content: space-between;
    width: max(25%, 60px);
}

.review-card-vote {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.review-card-vote img {
    /* https://codepen.io/sosuke/pen/Pjoqqp */
    height: 20px;
    width: 20px;
    margin-bottom: 2px;
    cursor: pointer;
}

.upvoted {
    filter: invert(25%) sepia(82%) saturate(1967%) hue-rotate(103deg) brightness(94%) contrast(105%);
}

.downvoted {
    filter: invert(15%) sepia(77%) saturate(7212%) hue-rotate(358deg) brightness(115%) contrast(112%);
}

.review-card-vote p {
    font-size: 0.8rem;
    color: rgba(0, 0, 0, 0.6);
    font-weight: 500;
}

.review-card-review p {
    font-size: 0.8rem;
    font-weight: 400;
    margin-top: 5px;
}

.faqs {
    padding: 2% 5%;
    margin-bottom: 5vh;
}

.faqs h3 {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 1.2rem;
    margin-bottom: 24px;
}

.faqs h3 img {
    height: 20px;
    margin-left: 10px;
}

.faq-card {
    padding: 0 2% 15px 2%;
    border-bottom: 2px solid #4B9ABD;
    margin-bottom: 15px;
}

.faq-question {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.faq-question i {
    cursor: pointer;
}

.faq-answer {
    color: rgba(0, 0, 0, 0.6);
    height: 0;
    overflow: hidden;
}

.faq-answer p {
    margin-top: 10px;
}

.dates-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
    display: none;
}

.dates-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: max(40%, 300px);
    height: 60%;
    background-color: white;
    border-radius: 5px;
    padding: 2%;
    z-index: 101;
}

.dates-modal-body {
    height: 80%;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.dates-modal-body::-webkit-scrollbar {
    display: none;
}

.dates-modal-body-date {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2vh;
}

.dates-modal-body-date .date-card {
    width: 60%;
    text-align: center;
}

.date-availability {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2vh;
}

.red {
    color: red;
}

.green {
    color: green;
}

.dates-modal button {
    width: 100%;
    height: 40px;
    border-radius: 5px;
    border: none;
    background-color: #4B9ABD;
    color: white;
    font-weight: 500;
    font-size: 1rem;
    margin-top: 2vh;
    cursor: pointer;
}


@media (max-width: 1000px) {
    .itinerary {
        display: block;
    }
    .itinerary-left {
        width: 100%;
    }
    .itinerary-right {
        width: 100%;
    }
    .days-nav, .days-nav-inner {
        width: 100%;
    }
    .date-nav-navigator {
        display: none;
    }
    .timeline-header {
        width: 88%;
    }
    .timeline-item {
        width: 100%;
        margin-left: 0;
    }
    .itinerary-price-item h2 {
        font-size: min(6vw, 28px);
    }
    .itinerary-price-item p {
        font-size: min(3vw, 20px);
    }
    .itinerary-book button {
        font-size: min(3vw, 20px);
    }
    .images-overlay-image img {
        height: 40vh;
    }
    .images-overlay {
        padding: 0;
    }
    .reviews {
        margin-top: 3vh;
        margin-bottom: 3vh;
    }
    .reviews-stats {
        width: 40%;
    }
    .reviews-overview {
        width: 20vw;
        height: 20vw;
    }
    .reviews-cards {
        width: 60%;
        max-height: 48vh;
    }
    .review-card {
        width: 100%;
        height: max-content;
    }
}

@media (max-width: 768px) {
    .dates-for-booking {
        margin-top: 3vh;
    }
    .timeline {
        margin-top: 3vh;
    }
    .assistance {
        font-size: 4vw;
    }
    .reviews-container {
        display: block;
    }
    .reviews-stats {
        width: 100%;
    }
    .reviews-overview {
        width: 40vw;
        height: 40vw;
    }
    .reviews-cards {
        width: 100%;
        margin-top: 3vh;
        margin-bottom: 5vh;
    }
    .review-card {
        width: 100%;
        height: 100%;
    }
    .dates-modal {
        width: 100%;
        height: 70%;
        transform: translate(0%, 0%);
        top: auto;
        left: 0;
        bottom: 0;
        padding: 5%;
    }
}