.search-bar {
    position: relative;
    bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    width: 50%;
    background-color: white;
    padding: 0 20px;
    border-radius: 10px;
    z-index: 2;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}

.search-icon i {
    font-size: 1.5rem;
    color: rgba(0, 0, 0, 0.6);
    padding: 10px 0;
}

.search-input {
    position: relative;
    width: 80%;
    height: 60px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    cursor: text;
}

.search-input input {
    color: rgba(0, 0, 0, 0.5);
    font-weight: 300;
    width: 100%;
    height: 100%;
    font-size: 1rem;
    border: none;
}

.search-input input:focus {
    outline: none;
}

.search-overlay {
    position: absolute;
    top: 50px;
    left: 0;
    background-color: white;
    width: 100%;
    border-radius: 0 0 10px 10px;
    overflow: hidden;
    height: 0;
    max-height: 200px;
    overflow: scroll;
    padding: 0 20px;
    transition: all linear 0.1s;
    cursor: default;
}

.search-location-card {
    padding: 0 20px 15px 20px;
    border-bottom: 1px solid #D9D9D9;
    margin-bottom: 15px;
    font-size: 0.9rem;
    cursor: pointer;
}

.search-location-card p {
    display: flex;
    align-items: center;
}

.search-location-card span {
    color: rgba(0, 0, 0, 0.5);
    font-size: 0.8rem;
}

.search-overlay-null {
    text-align: center;
    padding: 10px 0 20px 0;
    color: rgba(0, 0, 0, 0.5);
    font-size: 0.9rem;
}

.search-overlay-null a {
    color: #4B9ABD;
    text-decoration: none;
}

.search-go {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #4B9ABD;
    color: white;
    padding: 10px 0;
    height: 40px;
    width: 40px;
    border-radius: 10px;
    font-weight: 600;
    font-size: 1.5rem;
    cursor: pointer;
}

.search-submit {
    text-decoration: none;
}

@media (max-width: 768px) {
    .search-bar {
        width: 92%;
        border-radius: 5px;
    }
    .search-input {
        width: 70%;
    }
}