.home-carousel {
    padding: 2% 3%;
}

.home-carousel-image {
    height: 40vh;
    border-radius: 10px;
    background-color: rgb(204, 204, 204);
    animation: loadingCarousel 2s infinite;
}

.home-carousel img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    display: block;
    border-radius: 10px;
}

@keyframes loadingCarousel {
    0% {
        background-color: rgb(204, 204, 204);
    }
    50% {
        background-color: rgb(230, 230, 230);
    }
    100% {
        background-color: rgb(204, 204, 204);
    }
}

@media (max-width: 768px) {
    .home-carousel {
        padding: 0;
        padding-top: 85px;
    }
    .home-carousel-image {
        border-radius: 0;
        height: auto;
        min-height: 40vh;
    }
    .home-carousel-image img {
        border-radius: 0;
    }
}
