.legal-container {
    position: relative;
    padding: 5%;
}

.legal-container h1 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 2vh;
}

.legal-art-1 {
    position: absolute;
    top: 150px;
    left: 0;
    width: 200px;
    height: 100%;
    z-index: -1;
}

.legal-art-1 img {
    width: 100%;
    object-fit: cover;
    object-position: center;
}

.legal-container h1 span {
    color: #4B9ABD;
}

.last-updated {
    font-size: 0.8rem;
    color: rgba(0, 0, 0, 0.7);
    margin-bottom: 3vh;
}

.legal-text {
    letter-spacing: 0.5px;
    line-height: 1.5;
    margin-bottom: 2vh;
}

.legal-text b {
    font-weight: 500;
}

.legal-text span {
    color: var(--primary);
}

.legal-box {
    margin-top: 5vh;
    margin-bottom: 5vh;
}

.legal-box h2 {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 1vh;
    text-transform: uppercase;
}

.legal-box h3 {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 1vh;
    margin-top: 2vh;
}

.legal-box p {
    line-height: 1.5;
    margin-bottom: 2vh;
}

.legal-box p b {
    font-weight: 500;
}

.legal-box li {
    margin-left: 20px;
    margin-bottom: 1vh;
    line-height: 1.5;
}

.legal-art-2 {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 200px;
    z-index: -1;
}

.legal-art-2 img {
    width: 100%;
    object-fit: cover;
    object-position: center;
}