.popular-card-link {
    text-decoration: none;
}

.popular-destination {
    padding: max(2%, 10px) max(3%, 10px);
}

.popular-destination-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.popular-destination-header h2 {
    font-size: 1.4em;
    font-weight: 600;
}

.popular-destination-navigator {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.popular-destination-navigator-left {
    border: 1px solid #D9D9D9;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: white;
    color: #8A8A8A;
    cursor: pointer;
    margin-right: 10px;
}

.popular-destination-navigator-right {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: rgba(75, 154, 189, 0.2);
    color: #4B9ABD;
    cursor: pointer;
}

.popular-destination-cards {
    display: flex;
    overflow: scroll;
    margin-top: 20px;
    scroll-behavior: smooth;
}

.popular-destination-cards::-webkit-scrollbar {
    display: none;
}