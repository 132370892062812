.popular-destination-card {
    height: 270px;
    width: 350px;
    min-height: 270px;
    min-width: 350px;
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: max(1%, 10px);
    margin-right: 20px;
    transition: 0.4s;
    cursor: pointer;
    scale: 0.98;
}

.popular-destination-card:hover {
    scale: 1;
}

.popular-destination-image {
    height: 75%;
    width: 100%;
    border-radius: 10px;
    position: relative;
    background-color: rgb(204, 204, 204);
    animation: loadingCards 1s infinite;
}

.price-badge {
    position: absolute;
    right: 10px;
    top: 10px;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 5px;
    color: #4B9ABD;
    padding: 5px 10px;
    font-size: 0.8em;
}

.popular-destination-image img {
    height: 100%;
    width: 100%;
    border-radius: 10px;
    object-fit: cover;
}

.popular-destination-details {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.popular-destination-details h3 {
    color: #1C5981;
    font-weight: 600;
    font-size: 1.2em;
    margin-bottom: 3px;
}

.popular-destination-details p {
    color: black;
    font-size: 0.9em;
}

.popular-destination-details p i {
    color: #4B9ABD;
    margin-right: 5px;
    font-size: 0.9em;
}

@keyframes loadingCards {
    0% {
        background-color: rgb(204, 204, 204);
    }
    50% {
        background-color: rgb(230, 230, 230);
    }
    100% {
        background-color: rgb(204, 204, 204);
    }
}