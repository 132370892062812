.profile-navigator {
    width: 300px;
    background-color: #1C5981;
    padding: 25px;
    position: relative;
    height: calc(100vh - 100px);
}

.profile-navigator-header {
    height: 60px;
}

.profile-navigator-header img {
    height: 100%;
}

.profile-navigator-main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 30px;
}

.profile-navigator-option {
    margin-bottom: 50px;
}

.profile-navigator-option a {
    position: relative;
    text-decoration: none;
    color: white;
    font-weight: 500;
    font-size: 1.4em;
    padding: 10px 0;
    width: 100%;
    letter-spacing: 0.08em;
}

.profile-navigation-selected {
    position: absolute;
    width: 60%;
    height: 3px;
    background-color: #9FC4DC;
    margin-top: 10px;
}

.profile-navigator-footer {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 40%;
}

.profile-navigator-footer img {
    height: 100%;
    display: block;
}

@media (max-width: 768px) {
    .profile-navigator {
        display: none;
    }
}