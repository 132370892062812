.destinations-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: rgba(240, 240, 240, 0.98);
    backdrop-filter: blur(10px);
    display: flex;
    justify-content: space-between;
    padding: 0% 3%;
    height: 0;
    overflow: hidden;
    transition: 0.5s;
    z-index: 1000;
}

.destination-listing {
    display: flex;
    justify-content: left;
    align-items: flex-start;
    width: 100%;
    padding-top: 2%;
}

.destination-list {
    display: flex;
    flex-direction: column;
    margin-right: 10%;
}

.destination-list h3 {
    font-weight: 500;
    font-size: 1.4em;
}

.destination-list-underline {
    width: 100%;
    height: 2px;
    background-color: #4B9ABD;
    margin-top: 15px;
    margin-bottom: 10px;
}

.destination-list a {
    text-decoration: none;
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: 400;
    color: black;
    width: max-content;
}

.destination-list a:hover {
    color: #4B9ABD;
}

.destination-image {
    width: 30%;
    height: 100%;
    padding: 0% 2%;
}

.destination-image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    opacity: 0;
}