.booking {
    margin: auto;
    width: max(40%, 320px);
    background-color: #FCFCFF;
    padding: 3%;
    padding-bottom: 5vh;
}

.booking-animation {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 100%;
}

.booking-animation img {
    width: 50%;
}

.booking h2 {
    text-align: center;
    margin-top: 10px;
    font-size: 1.8em;
}

.booking h4 {
    text-align: center;
    margin-top: 10px;
    font-size: 1.2em;
}

.booking h5 {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 0.9em;
    font-weight: 500;
    color: green;
}

.booking table {
    width: 100%;
    margin-top: 10px;
    border-collapse: collapse;
}

.booking tr {
    border-bottom: 1px solid #E0E0E0;
}

.booking tr td {
    padding: 10px 0;
}

.booking tr td:nth-child(2) {
    text-align: right;
}

.booking button {
    width: 100%;
    margin-top: 10px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #4B9ABD;
    color: #FCFCFF;
    font-size: 1em;
    cursor: pointer;
}

@media (max-width: 768px) {
    .booking {
        width: 100%;
        padding: 20px;
        padding-top: 100px;
        padding-bottom: 5vh;
    }
}