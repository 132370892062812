.policies {
    padding: 2% 5%;
}

.policies h3 {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 2vh;
}

.policies h3 img {
    height: 20px;
    margin-left: 10px;
}

.cancellation-boxes {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3vh;
    margin-bottom: 5vh;
}

.cancellation-box {
    width: max(20%, 300px);
    height: 100%;
    margin: 0 2%;
    padding: 2%;
    border-radius: 5px;
    text-align: center;
}

.cancellation-box p {
    font-weight: 400;
}

.cancellation-box h4 {
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 1.4rem;
}

.positive-box {
    background-color: #1C5981;
    color: white;
}

.negative-box {
    border: 2px solid #B55757;
}

.terms-n-conditions {
    margin-bottom: 3vh;
}

.terms-n-conditions li {
    margin-bottom: 10px;
}

@media (max-width: 768px) {
    .policies h3 {
        margin-top: 3vh;
    }
    .cancellation-boxes {
        display: block;
    }
    .cancellation-box {
        width: 100%;
        margin: 0;
        margin-bottom: 2vh;
    }
    .terms-n-conditions {
        width: 90%;
        margin: auto;
        margin-bottom: 3vh;
    }
}