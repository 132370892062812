.trip-card-anchor {
    text-decoration: none;
    color: black;
}

.trip-card {
    position: relative;
    width: min(320px, 95%);
    height: 440px;
    min-width: min(320px, 95%);
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 5vh;
    margin-right: 20px;
    margin-left: 20px;
}

.trip-card-overlay {
    position: absolute;
    z-index: 1;
}

.winter-special {
    left: -8px;
    bottom: 0;
    width: calc(100% + 14px);
    height: calc(100% + 14px);
}

.christmas-special {
    left: 0;
    bottom: 0;
    width: calc(100% + 40px);
    height: calc(100% + 40px);
}

.honeymoon-special {
    left: 0;
    bottom: 0;
    width: calc(100% + 10px);
    height: calc(100% + 10px);
}

.trip-card-overlay img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 10px;
}

.trip-card-header {
    position: relative;
    height: 35%;
}

.trip-card-image {
    height: 100%;
    width: 100%;
    border-radius: 10px;
    background-color: rgb(204, 204, 204);
    animation: loadingCards 1s infinite;
}

.trip-card-image img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.trip-card-rating {
    position: absolute;
    font-size: 0.9em;
    top: 10px;
    left: 10px;
    background-color: #1C5981;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: max-content;
    border-radius: 100px;
}

.trip-card-rating img {
    height: 16px;
    margin-right: 5px;
}

.trip-card-rating p {
    color: white;
    font-weight: 500;
    padding-top: 1px;
}

.trip-card-wishlist {
    position: absolute;
    top: 10px;
    right: 10px;
    height: 40px;
    width: 40px;
    background-color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 2;
}

.trip-card-wishlist i {
    font-size: 1em;
}

.trip-card-duration {
    background-color: #1C5981;
    font-size: 0.9em;
    color: white;
    padding: 10px;
    border-radius: 100px;
    position: absolute;
    bottom: 10px;
    left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.trip-card-duration img {
    height: 16px;
    margin-right: 5px;
}

.trip-card-duration p {
    font-size: 0.9em;
    font-weight: 500;
}

.trip-card-offer {
    position: absolute;
    bottom: 0px;
    right: -19px;
}

.trip-card-offer-image {
    height: 58px;
}

.trip-card-offer-image img {
    height: 100%;
}

.trip-card-offer-text {
    position: absolute;
    top: 20px;
    left: 16px;
}

.trip-card-offer-text p {
    font-weight: 700;
    color: white;
    font-size: 0.9em;
}

.trip-card-main {
    padding: 5% 0;
    position: relative;
}

.trip-card-location {
    display: flex;
    justify-content: center;
    align-items: center;
}

.trip-card-location h2 {
    font-weight: 700;
    font-size: 1em;
}

.trip-card-location img {
    height: 12px;
    margin-left: 10px;
    margin-right: 10px;
}

.trip-card-inclusions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(159, 196, 220, 0.3);
    padding: 10px;
    border-radius: 10px;
    width: 90%;
    margin: auto;
    margin-top: 10px;
}

.trip-card-inclusion {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.trip-card-inclusion img {
    height: 16px;
    margin-bottom: 5px;
}

.trip-card-inclusion p {
    font-size: 0.6em;
    font-weight: 500;
}

.inactive-inclusion {
    color: rgba(0, 0, 0, 0.3);
}

.trip-card-highlights {
    width: 85%;
    margin: auto;
    margin-top: 10px;
    padding-left: 10px;
}

.trip-card-highlights ul li {
    margin-bottom: 6px;
    font-weight: 500;
    font-size: 0.9em;
}

.trip-card-footer {
    height: 15%;
}

.trip-card-price {
    position: absolute;
    right: 0;
    bottom: 0;
}

.trip-card-price img {
    border-radius: 0 0 10px 0;
    display: block;
}

.trip-card-price-details {
    position: absolute;
    top: 7px;
    right: 15px;
    color: white;
}

.trip-card-price-old {
    text-decoration: line-through;
    font-size: 0.8em;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.6);
    text-align: right;
}

.trip-card-price-new {
    font-size: 1.5em;
    font-weight: 800;
}

.trip-card-price-text {
    font-size: 0.7em;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.8);
    text-align: right;
}

.trip-card-wishlisted {
    color: red;
}

.trip-card-departure-date {
    width: 50%;
    margin-left: 15px;
    padding-top: 8px;
    height: 100%;
}

.trip-card-departure-date h4 {
    color: #0B8900;
    font-weight: 600;
    margin-bottom: 5px;
    font-size: 1em;
}

.trip-card-departure-date p {
    font-size: 0.8em;
}

@media (max-width: 420px) {
    .trip-card {
        margin-right: 20px;
        margin-left: 0;
        min-width: 90%;
    }   
}

@keyframes loadingCards {
    0% {
        background-color: rgb(204, 204, 204);
    }
    50% {
        background-color: rgb(230, 230, 230);
    }
    100% {
        background-color: rgb(204, 204, 204);
    }
}