.about-header {
    width: 100%;
}

.about-header img {
    width: 100%;
}

.about-details {
    padding: max(5%, 26px);
}

.about-box {
    padding: 0 5%;
    margin-bottom: 5vh;
}

.about-box h2 {
    font-size: 600;
    font-size: 2em;
}

.about-box h2 span {
    color: #10405D;
}

.about-box-inner {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.about-box-inner p {
    padding-top: 20px;
    font-weight: 400;
    color: #3C3C3C;
    line-height: 160%;
    width: 65%;
}

.about-blue-text {
    color: #10405D;
    font-weight: 600;
}

.about-bold-text {
    font-weight: 500;
}

.about-box-inner img {
    width: max(20%, 250px);
}

.about-team-text {
    font-weight: 400;
    color: #3C3C3C;
    line-height: 160%;
    padding-top: 10px;
}

.about-team-cards {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 5%;
    padding-bottom: 0;
}

.about-team-card {
    width: 200px;
    height: 280px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 15px 20px;
}

.about-team-card-image {
    position: relative;
    width: 200px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.about-team-card-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.about-team-card-image a {
    position: absolute;
    top: 5px;
    right: 8px;
    color: black;
    font-size: 1.5em;
}

.about-team-card-details {
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5%;
}

.about-team-card-details p {
    font-weight: 700;
    margin-bottom: 3px;
}

.about-team-card-details span {
    color: rgba(0, 0, 0, 0.6);
    font-size: 0.8em;
}

.about-stats {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 3% 5%;
    margin-bottom: 50px;
}

.about-stat-item {
    margin: 0 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.about-stat-item-image {
    width: max(50%, 50px);
}

.about-stat-item-image img {
    width: 100%;
}

.about-stat-item-info {
    text-align: center;
    margin-top: 10px;
}

.about-stat-item-info p {
    font-weight: 700;
    margin-bottom: 3px;
    font-size: 1.3em;
}

.about-stat-item-info span {
    color: rgba(0, 0, 0, 0.8);
    font-size: 0.9em;
}

.about-recognitions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 3% 5%;
}

.about-recognition {
    width: 140px;
    margin: 30px 70px;
}

.about-recognition img {
    width: 100%;
}

@media (max-width: 768px) {
    .about-header {
        padding-top: 85px;
    }
    .about-box {
        padding: 0;
    }
    .about-box-inner {
        flex-direction: column;
        align-items: center;
    }
    .about-box-inner p {
        width: 100%;
        order: 2;
        text-align: justify;
    }
    .about-box-inner img {
        width: 50%;
        order: 1;
        margin-bottom: 20px;
    }
    .about-team-text {
        text-align: justify;
    }
    .about-stat-item {
        margin: 0 10px;
    }
    .about-stat-item-info p {
        font-size: 4vw;
        margin-bottom: 0;
    }
    .about-stat-item-info span {
        font-size: 2.5vw;
    }
    .about-recognitions {
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-wrap: wrap;
        padding: 3% 5%;
    }    
    .about-recognition {
        width: 100px;
        margin: 20px 5vw;
    }
    .about-recognition img {
        width: 100%;
    }    
}