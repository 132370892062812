.itinerary-images {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70vh;
}

.itinerary-rating {
    position: absolute;
    background-color: #1C5981;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    border-radius: 10px;
    top: 5vh;
    left: 3%;    
    z-index: 2;
}

.itinerary-rating img {
    height: 20px;
    margin-right: 8px;
}

.itinerary-rating p {
    color: white;
    font-weight: 600;
}

.left-image {
    position: relative;
    width: 65%;
    height: 100%;
}

.left-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
}

.itinerary-details {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    bottom: 0;
    background: linear-gradient(360deg, #000000 -10.16%, rgba(0, 0, 0, 0) 100%);
    padding: 4% 5%;
}

.itinerary-from-to {
    display: flex;
    align-items: center;
    width: 100%;
}

.itinerary-from-to p {
    color: white;
    font-weight: 600;
    font-size: 1.5rem;
}

.itinerary-from-to img {
    height: 30px;
    width: 30px;
    margin: 0 30px;
}

.itinerary-category {
    color: rgba(255, 255, 255, 0.8);
    font-size: 1.5rem;
}

.right-images {
    position: relative;
    width: 35%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.right-image {
    width: 50%;
    height: 50%;
}

.right-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
}

.all-photos {
    position: absolute;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
    top: 50%;
    left: 50%;
    width: 50%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.all-photos p {
    color: white;
    font-weight: 500;
    font-size: 1rem;
    text-decoration: underline;
    cursor: pointer;
}

.images-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    padding: 0 5%;
    z-index: 1000;
    justify-content: space-between;
    align-items: center;
}

.images-overlay-arrow {
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    color: white;
    font-size: 2rem;
    cursor: pointer;
}

.images-overlay-image {
    width: 80%;
}

.images-overlay-image img {
    width: 100%;
    height: 70vh;
    object-fit: contain;
    display: none;
}

.close-image-overlay {
    position: absolute;
    top: calc(64px + 2%);
    right: max(2%, 20px);
    font-size: 2rem;
    color: white;
    cursor: pointer;
}

.selected-image {
    display: block !important;
}

@media (max-width: 768px) {
    .itinerary-images {
        flex-direction: column;
        height: 60vh;
    }
    .itinerary-rating {
        top: 3vh;
        left: auto;
        right: 7%;
    }
    .itinerary-rating img {
        height: 15px;
    }
    .itinerary-rating p {
        font-size: 0.9rem;
    }
    .left-image {
        width: 100%;
        height: 60%;
    }
    .right-images {
        width: 100%;
        height: 40%;
    }
    .right-image {
        height: 100%;
    }
    .all-photos {
        height: 100%;
        top: 0;
        left: 0;
        width: 100%;
    }
    .right-image:nth-child(1), .right-image:nth-child(2) {
        display: none;
    }
    .itinerary-details {
        padding: 4% 7%;
        flex-direction: column;
        justify-content: left;
    }
    .itinerary-category {
        font-size: 1rem;
        width: 100%;
    }
    .itinerary-from-to {
        justify-content: left;
        width: 100%;
        margin-bottom: 5px;
    }
}