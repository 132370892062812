footer {
    background-color: white;
    width: 100%;
}

.footer-main {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 3% 5%;
}

.footer-logo {
    width: 30%;
}

.footer-logo img {
    width: max(60%, 250px);
}

.footer-socials {
    display: flex;
    justify-content: space-between;
    width: 100px;
    padding-left: 8px;
    margin-top: 15px;
}

.footer-socials img {
    width: 20px;
}

.footer-links {
    width: 40%;
    display: flex;
    justify-content: space-around;
}

.footer-link-container {
    display: flex;
    flex-direction: column;
    width: 48%;
}

.footer-link-container h3 {
    color: #303030;
    font-weight: 500;
    font-size: 1.1em;
    text-transform: lowercase;
}

.footer-link-container a {
    text-decoration: none;
    color: #303030;
    font-weight: 300;
    font-size: 1em;
    margin-top: 12px;
    width: max-content;
}

.footer-link-container a:hover {
    color: #4B9ABD;
}

.footer-newsletter {
    width: 30%;
    display: flex;
    flex-direction: column;
}

.footer-newsletter h3 {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 1.1em;
}

.footer-newsletter h3 img {
    height: 15px;
    margin-right: 5px;
}

.footer-newsletter input {
    border: 1.45156px solid #4B9ABD;
    width: 80%;
    font-size: 0.9em;
    padding: 8px 10px;
    margin-top: 10px;
    border-radius: 5px;
}

.footer-newsletter input:focus {
    outline: 1px solid #4B9ABD;
}

.footer-newsletter button {
    background-color: #4B9ABD;
    border: none;
    color: white;
    width: 120px;
    font-weight: 600;
    padding: 8px 10px;
    border-radius: 5px;
    margin-top: 10px;
    cursor: pointer;
}

.footer-contact {
    border-top: 2.5px solid rgba(4, 135, 218, 0.4);
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: max(2.5%,15px) 3%;
}

.footer-contact-detail {
    display: flex;
    align-items: center;
    margin-right: 20px;
    margin-left: 20px;
}

.footer-contact-detail img {
    margin-right: 5px;
    height: 20px;
}

.footer-contact-detail a {
    text-decoration: none;
    color: rgba(0,0,0,0.8);
}

.footer-copyright {
    background-color: #1C5981;
    color: white;
    width: 100%;
    padding: 2% 5%;
    font-weight: 600;
}

@media (max-width: 1024px) {
    .footer-main {
        flex-direction: column;
    }
    .footer-logo {
        order: 0;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .footer-logo img {
        width: min(50%, 200px);
    }
    .footer-socials img {
        width: 20px;
        padding-top: 11px;
    }
    .footer-newsletter {
        order: 1;
        width: 100%;
        margin-top: 20px;
        padding-left: 6px;
    }
    .footer-newsletter-input {
        display: flex;
        justify-content: space-between;
    }
    .footer-newsletter h3 {
        font-size: 0.9em;
    }
    .footer-newsletter h3 img {
        height: 12px;
    }
    .footer-newsletter input {
        width: 70%;
        font-size: 0.8em;
    }
    .footer-newsletter button {
        width: 25%;
        padding: 8px 3%;
    }
    .footer-links {
        order: 2;
        width: 100%;
        margin-top: 20px;
        padding-left: 2px;
    }
    .footer-link-container h3 {
        font-size: 0.9em;
    }
    .footer-link-container a {
        font-size: 0.8em;
    }
    .footer-contact-detail {
        margin-right: 10px;
        margin-left: 10px;
    }
    .footer-contact-detail img {
        height: 12px;
    }
    .footer-contact-detail a {
        font-size: min(2.2vw, 12px);
    }
    .footer-copyright {
        padding: min(4%, 16px) 5%;
        text-align: center;
        font-size: min(3vw, 16px);
    }
}