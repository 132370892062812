.my-profile {
    width: 80%;
    padding: 5%;
    height: calc(100vh - 100px);
    overflow: scroll;
}

.profile-header {
    position: relative;
    width: 100%;
    height: 350px;
}

.profile-cover {
    height: 60%;
    border-radius: 10px;
}

.profile-cover img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.profile-photo {
    position: absolute;
    top: 100px;
    left: 50px;
    background-color: white;
    height: 200px;
    width: 200px;
    padding: 5px;
    border-radius: 50%;
}

.profile-photo img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.profile-photo i {
    position: absolute;
    top: 10px;
    right: 10px;
    height: 40px;
    width: 40px;
    background-color: #1C5981;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 50%;
    box-shadow: 0px 10.3685px 20.7371px rgba(0, 0, 0, 0.15);
    cursor: pointer;
}

.profile-details {
    position: absolute;
    left: 270px;
    top: 220px;
}

.profile-details h1 {
    font-weight: 500;
    font-size: 1.4em;
    margin-bottom: 5px;
}

.profile-details p {
    font-size: 0.9em;
    color: #4B9ABD;
}

.profile-details i {
    color: #1C5981;
    cursor: pointer;
    margin-left: 10px;
}

.profile-detail-box {
    border: 1px solid #4B9ABD;
    padding: 30px 40px;
    border-radius: 10px;
    background-color: white;
    margin-bottom: 30px;
}

.profile-details-box-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.profile-details-box-header h2 {
    font-weight: 600;
    font-size: 1.4em;
}

.profile-details-box-header i {
    height: 40px;
    width: 40px;
    background-color: #1C5981;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 50%;
    box-shadow: 0px 10.3685px 20.7371px rgba(0, 0, 0, 0.15);
    cursor: pointer;
}

.profile-details-box-details {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.profile-detail {
    margin-bottom: 10px;
    margin-top: 10px;
}

.profile-detail h4 {
    color: rgba(0, 0, 0, 0.4);
    font-weight: 400;
    font-size: 1em;
    margin-bottom: 5px;
}

.profile-detail p {
    font-size: 1em;
    font-weight: 500;
}

.profile-interests {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
}

.profile-interest {
    border: 1px solid #4B9ABD;
    color: #4B9ABD;
    font-size: 1em;
    padding: 5px 10px;
    border-radius: 5px;
    margin-right: 10px;
    margin-bottom: 10px;
    cursor: pointer;
}

.selected-interest {
    background-color: #4B9ABD;
    color: white;
}

.profile-edit-add {
    color: #4B9ABD;
    width: max-content;
    text-decoration: underline;
    cursor: pointer;
}

@media (max-width: 768px) {
    .my-profile {
        padding-top: 100px;
        width: 100%;
        height: 100%;
    }
    .profile-header {
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .profile-cover {
        display: none;
    }
    .profile-photo {
        position: relative;
        top: auto;
        left: auto;
    }
    .profile-details {
        position: relative;
        left: auto;
        top: auto;
        text-align: center;
        margin-top: 20px;
        margin-bottom: 30px;
    }
    .profile-details-box-details {
        display: block;
    }
}