.checkout {
    padding: 3%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.checkout-main {
    width: 70%;
}

.checkout-section {
    margin-bottom: 30px;
}

.checkout-section h2 {
    display: flex;
    align-items: center;
    font-size: 1.2em;
    font-weight: 500;
}

.checkout-section h2 img {
    height: 20px;
    margin-left: 10px;
}

.date-selector {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.date-selector input, .date-selector select {
    width: 30%;
    padding: 10px;
    border: 1px solid #1C5981;
    border-radius: 10px;
    font-size: 0.9em;
}

.seat-availability {
    margin-top: 15px;
    font-weight: 600;
    color: green;
    margin-bottom: 3vh;
}

.red {
    color: red !important;
}

.traveller-details {
    width: 100%;
    margin-top: 10px;
}

.traveller-details table {
    width: 90%;
    border-collapse: collapse;
}

.traveller-details table tr {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.traveller-details table tr td, .traveller-details table tr th {
    padding: 10px 0;
    font-size: 0.9em;
    text-align: left;
}

.traveller-details table tr th {
    font-weight: 500;
}

.traveller-details table tr td i {
    height: 25px;
    width: 25px;
    background-color: #4B9ABD;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 50%;
    font-size: 0.8em;
    cursor: pointer;
}

.traveller-details button {
    background-color: #4B9ABD;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 0.9em;
    margin-top: 10px;
    cursor: pointer;
}

.add-traveller-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.5);
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
}

.add-traveller-modal-content {
    width: max(30%, 300px);
    background-color: white;
    border-radius: 10px;
    padding: 2%;
}

.traveller-modal-input p {
    font-size: 0.8em;
    color: rgba(0, 0, 0, 0.5);
    margin-bottom: 5px;
}

.traveller-modal-input {
    margin-bottom: 15px;
}

.traveller-modal-input input {
    width: 100%;
    border: 1px solid #4B9ABD;
    border-radius: 5px;
    padding: 10px;
    font-size: 0.9em;
}

.traveller-modal-input select {
    width: 100%;
    border: 1px solid #4B9ABD;
    border-radius: 5px;
    padding: 10px;
    font-size: 0.9em;
    cursor: pointer;
}

.traveller-modal-input input:focus {
    outline: 1px solid #4B9ABD;
}

.traveller-modal-input select:focus {
    outline: 1px solid #4B9ABD;
}

.traveller-modal-error {
    color: red;
    font-size: 0.8em;
}

.traveller-modal-error p {
    margin-bottom: 5px;
}

.traveller-modal-buttons {
    display: flex;
}

.traveller-modal-buttons button {
    background-color: #4B9ABD;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 0.9em;
    margin-top: 10px;
    cursor: pointer;
    margin-right: 10px;
}

.traveller-modal-delete-btn {
    background-color: #E43B4E !important;
}

.add-email {
    margin-top: 15px;
    padding-bottom: 15px;
    border-bottom: 3px dashed #1C5981;
    width: 90%;
}

.add-email p {
    font-size: 0.8em;
    color: rgba(0, 0, 0, 0.5);
    margin-bottom: 10px;
}

.add-email input {
    width: 40%;
    border: 1px solid #4B9ABD;
    border-radius: 5px;
    padding: 10px;
    font-size: 0.9em;
}

.add-email input:focus {
    outline: 1px solid #4B9ABD;
}

.add-email button {
    background-color: #4B9ABD;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 0.9em;
    margin-left: 10px;
    cursor: pointer;
    margin-right: 10px;
}

.add-email button:disabled {
    background-color: rgba(0, 0, 0, 0.2);
    cursor: default;
}

.add-address {
    margin-top: 15px;
    width: 90%;
}

.add-address-input-line {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.add-address-input {
    width: 45%;
    margin-bottom: 15px;
}

.add-address-input-line p {
    font-size: 0.8em;
    color: rgba(0, 0, 0, 0.5);
    margin-bottom: 10px;
}

.add-address-input-line input {
    width: 100%;
    border: 1px solid #4B9ABD;
    border-radius: 5px;
    padding: 10px;
    font-size: 0.9em;
}

.add-address-input-line select {
    width: 100%;
    border: 1px solid #4B9ABD;
    border-radius: 5px;
    padding: 10px;
    font-size: 0.9em;
    cursor: pointer;
}

.add-address-input-line input:focus {
    outline: 1px solid #4B9ABD;
}

.add-address-input-line select:focus {
    outline: 1px solid #4B9ABD;
}

.add-address button {
    margin-top: 10px;
    background-color: #4B9ABD;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 0.9em;
    cursor: pointer;
}

.error-input {
    border: 1px solid red !important;
}

.error-input:focus {
    outline: 1px solid red !important;
}

.checkout-secondary {
    width: 30%;
}

.itinerary-summary {
    width: 90%;
    margin: auto;
    background-color: #4B9ABD;
    padding: 3%;
    border-radius: 10px;
}

.itinerary-image {
    width: 100%;
    height: 70%;
    border-radius: 10px;
}

.itinerary-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.itinerary-detail-1 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.itinerary-detail-1 p {
    font-size: 1.2em;
    font-weight: 600;
    color: white;
}

.itinerary-detail-1 img {
    height: 26px;
    margin-left: 10px;
    margin-right: 10px;
}

.itinerary-detail-2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.itinerary-detail-inner {
    width: 33%;
    text-align: center;
}

.itinerary-detail-inner p {
    color: rgba(255, 255, 255, 0.6);
    font-weight: 600;
    font-size: 0.8em;
    margin-bottom: 5px;
}

.itinerary-detail-inner span {
    color: white;
    font-weight: 500;
    font-size: 1em;
}

.checkout-secondary-section {
    width: 90%;
    margin: auto;
    margin-top: 30px;
}

.checkout-secondary-section h2 {
    display: flex;
    align-items: center;
    font-size: 1.2em;
    font-weight: 500;
}

.checkout-secondary-section h2 img {
    height: 20px;
    margin-left: 10px;
}

.promocode {
    margin-top: 15px;
}

.promocode-input {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.promocode-input input {
    width: 70%;
    padding: 10px 15px;
    border: 1px solid #4B9ABD;
    border-radius: 5px;
    font-size: 0.9em;
}

.promocode-input input:focus {
    outline: 1px solid #4B9ABD;
}

.promocode-input button {
    background-color: #4B9ABD;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 0.9em;
    cursor: pointer;
}

.promocode-cards {
    margin-top: 10px;
    display: flex;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.promocode-cards::-webkit-scrollbar {
    display: none;
}

.promocode-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    padding: 3%;
    border: 1px solid #4B9ABD;
    border-radius: 10px;
    min-width: 200px;
    width: 60%;
    margin-right: 10px;
    cursor: pointer;
}

.promocode-icon {
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.promocode-icon img {
    width: 60%;
}

.promocode-details {
    width: 75%;
}

.promocode-details p {
    font-size: 0.8em;
    margin-bottom: 2px;
}

.promocode-details span {
    font-size: 0.8em;
    font-weight: 500;
}

.payment {
    margin-top: 15px;
    background-color: white;
    padding: 5% 3%;
    border-radius: 10px;
    border: 2px solid #4B9ABD;
}

.payment table {
    width: 95%;
    border-collapse: collapse;
    margin: auto;
}

.payment table tr {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.payment table tr td {
    padding: 10px 0;
    text-align: left;
}

.payment table tr td:nth-child(2) {
    text-align: right !important;
}

.payment button {
    width: 95%;
    margin-left: 2.5%;
    background-color: #4B9ABD;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 0.9em;
    cursor: pointer;
    margin-top: 10px;
}

.payment-modal {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
}

.payment-modal-content {
    width: max(50%, 500px);
    height: 500px;
    background-color: white;
    padding: 1%;
    border-radius: 10px;
}

.payment-modal-content iframe {
    width: 100%;
    height: 130%;
    border: none;
}

.sharing-options {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-top: 20px;
}

.sharing-card-outer {
    width: 32%;
}

.sharing-card-outer h3 {
    text-align: center;
    margin-bottom: 15px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
}

.sharing-card {
    background: rgba(159, 196, 220, 0.3);
    border-radius: 10px;
}

.sharing-card-search {
    position: relative;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding: 10px;
}

.sharing-card-search input {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: none;
}

.sharing-card-search input:focus {
    outline: none;
}

.sharing-search-list {
    position: absolute;
    background-color: white;
    width: calc(100% - 20px);
    transform: translateY(-5px);
    border-radius: 0 0 10px 10px;
    max-height: 20vh;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.sharing-search-list::-webkit-scrollbar {
    display: none;
}

.sharing-search-list p {
    margin: 10px;
    font-size: 0.8em;
    font-weight: 500;
    cursor: pointer;
}

.sharing-search-list p:hover {
    color: #4B9ABD;
}

.sharing-card-names {
    padding: 20px;
    height: 250px;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.sharing-card-names::-webkit-scrollbar {
    display: none;
}

.sharing-card-name {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.9em;
    margin-bottom: 20px;
}

.sharing-card-name p {
    width: 80%;
    word-wrap: break-word;
    font-weight: 600;
}

.sharing-card-name i {
    font-weight: 400;
    cursor: pointer;
}

.sharing-card-footer {
    padding: 10px;
    background: rgba(159, 196, 220, 0.35);
    border-radius: 0 0 10px 10px;
    text-align: center;
}

.sharing-card-footer p {
    font-size: 0.9em;
}

.sharing-card-footer p span {
    font-weight: 600;
}

.sharing-note {
    margin-top: 20px;
    font-size: 0.9em;
    color: rgba(0, 0, 0, 0.6);
    font-style: italic;
}

@media (max-width: 768px) {
    .checkout {
        display: block;
        padding: 20px;
        padding-top: 100px;
    }
    .checkout-main {
        width: 100%;
        margin: auto;
    }
    .date-selector input {
        width: 50%;
    }
    .traveller-table {
        max-width: 100vw;
        overflow: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
    .traveller-table::-webkit-scrollbar {
        display: none;
    }
    .traveller-details table {
        width: 100vw;
        overflow: scroll;
    }
    .traveller-details table tr td, .traveller-details table tr th {
        white-space: nowrap;
        padding: 10px;
    }
    .checkout-secondary {
        width: 100%;
        padding-bottom: 10vh;
    }
    .itinerary-summary {
        width: 100%;
    }
    .checkout-secondary-section {
        width: 100%;
    }
    .add-traveller-modal-content {
        width: 100%;
        position: absolute;
        bottom: 0;
        border-radius: 10px 10px 0 0;
        padding: 20px;
        max-height: 70%;
        overflow: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
    .add-traveller-modal-content::-webkit-scrollbar {
        display: none;
    }
    .payment-modal-content {
        width: 100%;
        position: absolute;
        bottom: 0;
        border-radius: 10px 10px 0 0;
        max-height: 70%;
        overflow: scroll;
    }
    .sharing-options {
        width: 100%;
        flex-direction: column;
        align-items: center;
    }
    .sharing-card-outer {
        width: 100%;
        margin-bottom: 20px;
        background-color: white;
        padding: 5%;
        border-radius: 10px;
    }
}