.filter-bar {
    position: sticky;
    top: 56px;
    background-color: #4B9ABD;
    padding: 0 3%;
    height: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 999;
}

.filter-left {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: max(60%, 600px);
}

.filter-icon {
    height: 40%;
}

.filter-icon img {
    height: 100%;
}

.filter-options {
    display: flex;
    align-items: center;
    width: 90%;
}

.filter-option {
    position: relative;
    margin-right: 70px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.filter-option p {
    margin-right: 5px;
    font-weight: 500;
}

.filter-dropdown {
    position: absolute;
    top: 180%;
    left: 0;
    background-color: rgb(75, 154, 189, 1);
    width: max-content;
    border-radius: 0 0 10px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 0;
    padding: 0 20px;
    overflow: hidden;
    z-index: 1;
    transition: 0.1s;
}

.filter-dropdown-item {
    display: flex;
    width: 100%;
    align-items: center;
    margin: 5px 0;
}

.filter-dropdown-item input {
    margin-right: 5px;
}

.filter-right {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.filter-right img {
    height: 16px;
    margin-right: 10px;
}

.filter-right p {
    color: white;
    font-weight: 500;
}

.sort-by-dropdown {
    position: absolute;
    top: 180%;
    right: -3vw;
    background-color: rgb(75, 154, 189, 1);
    width: max-content;
    border-radius: 0 0 10px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 0;
    padding: 0 20px;
    overflow: hidden;
    z-index: 1;
    transition: 0.1s;
}

.sort-by-dropdown-item {
    display: flex;
    width: 100%;
    align-items: center;
    margin: 5px 0;
    color: white;
}

.sort-by-dropdown-item input {
    margin-right: 5px;
}

.filter-option span {
    position: absolute;
    bottom: -7px;
    left: -5px;
    transform: translateY(-50%);
    width: 120%;
    height: 2px;
    background-color: white;
}

@media (max-width: 768px) {
    .filter-bar {
        top: 85px;
        padding: 0 5%;
    }
    .filter-options {
        display: none;
        flex-direction: column;
        position: absolute;
        top: 56px;
        left: 0;
        width: 100%;
        background-color: rgba(75, 154, 189, 0.95);
        padding: 5%;
        justify-content: left;
        align-items: flex-start;
    }
    .filter-option {
        margin-right: 0;
        margin-bottom: 20px;
    }
    .filter-option span {
        width: 100px;
        bottom: -10px;
    }
    .filter-dropdown {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        border-radius: 0;
        padding: 0;
        height: max-content;
        background-color: transparent;
    }
    .filter-left {
        width: auto;
    }
}