.wishlist-cards {
    width: 80%;
    padding: 5%;
    height: calc(100vh - 100px);
    overflow: scroll;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

@media (max-width: 768px) {
    .wishlist-cards {
        padding-top: 100px;
        height: auto;
    }
}