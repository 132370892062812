.travconnect-header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
}

.travconnect-image {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 50px;
}

.travconnect-image img {
    height: 90%;
}

.travconnect-image:nth-child(1) {
    order: 2;
}

.travconnect-image:nth-child(2) {
    order: 1;
}

.trip-cards {
    padding: 3%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

@media (max-width: 768px) {
    .travconnect-header {
        height: auto;
        width: 100%;
        display: block;
        padding-top: 120px;
    }
    .travconnect-image {
        width: 100%;
        height: auto;
        margin: 0;
        margin-bottom: 30px;
    }
    .travconnect-image img {
        width: 70%;
        height: auto;
    }
}