.query-btn {
    position: fixed;
    right: -20px;
    top: 35%;
    background-color: #4b9abd;
    height: 80px;
    color: white;
    display: flex;
    text-align: center;
    cursor: pointer;
    border-radius: 10px 0 0 10px;
    /* Wait for 5s before running again */
    animation: move-query-btn 5s infinite;
}

.query-btn-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 60px;
}

.query-btn i {
    font-size: 20px;
    margin-bottom: 3px;
}

.query-btn p {
    font-size: 12px;
    margin: 0;
}

.query-btn-extension {
    width: 20px;
    height: 80px;
    background-color: white;
}

@keyframes move-query-btn {
    0% {
        transform: translateX(0);
    }
    10% {
        transform: translateX(-20px);
    }
    20% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(0);
    }
}

.query-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.query-box {
    width: max(40%, 500px);
    background-color: white;
    border-radius: 10px;
    padding: 40px;
    display: flex;
    flex-direction: column;
}

.query-box h2 {
    margin: 0;
    font-size: 1.6rem;
    font-weight: 500;
    text-align: center;
    margin-bottom: 10px;
}

.query-box h4 {
    margin: 0;
    font-size: 1rem;
    font-weight: 400;
    text-align: center;
    margin-bottom: 5px;
}

.query-box p {
    margin: 0;
    font-size: 0.8rem;
    font-weight: 400;
    text-align: center;
    margin-bottom: 10px;
    color: rgba(0, 0, 0, 0.5);
}

.query-box p a {
    color: #4b9abd;
}

.query-form-fields {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
}

.query-form-field {
    width: 100%;
    padding: 0 10px;
}

.query-form-field input, .query-form-field textarea {
    border: 1px solid #1C5981;
    padding: 10px;
    border-radius: 5px;
    width: 100%;
    font-size: 0.85rem;
    resize: none;
}

.query-form-tnc {
    display: flex;
    align-items: center;
    margin: 10px 0;
    padding: 0 10px;
}

.query-form-tnc input {
    margin-right: 5px;
}

.query-form-tnc label {
    font-size: 0.9rem;
    color: rgba(0, 0, 0, 0.5);
}

.query-form-tnc label a {
    color: #4b9abd;
    text-decoration: none;
}

.query-form-fields button {
    margin: auto;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #4b9abd;
    color: white;
    font-size: 0.9rem;
    cursor: pointer;
}

.query-form-fields button i {
    margin: 0 5px;
    font-size: 0.7rem;
}

.query-form-fields button i:nth-child(1) {
    animation: bouncing-balls 1s infinite;
}

.query-form-fields button i:nth-child(2) {
    animation: bouncing-balls 1s infinite 0.2s;
}

.query-form-fields button i:nth-child(3) {
    animation: bouncing-balls 1s infinite 0.4s;
}

.query-form-fields:last-child {
    margin: 0;
}

@media (max-width: 768px) {
    .query-modal {
        align-items: flex-end;
    }
    .query-box {
        width: 100%;
        border-radius: 10px 10px 0 0;
        padding: 40px 20px;
    }
    .query-box h2 {
        font-size: 5vw;
    }
    .query-box h4 {
        font-size: 3.8vw;
    }
    .query-box p {
        font-size: 3.4vw;
        line-height: 160%;
    }
    .query-form-fields {
        flex-direction: column;
        margin: 0;
    }
    .query-form-field:first-child {
        margin: 10px 0;
    }
    .query-form-field input, .query-form-field textarea {
        font-size: 3vw;
    }
    .query-form-field textarea {
        height: 100px;
    }
    .query-form-fields button {
        margin: 10px 0;
        font-size: 4.5vw;
    }
    .query-form-fields button i {
        font-size: 3vw;
    }
    .query-form-tnc label {
        font-size: 3.6vw;
    }
}

@keyframes bouncing-balls {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
    100% {
        transform: translateY(0);
    }
}