.profile-edit-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.profile-edit-modal-content {
    width: max(30%, 300px);
    background-color: white;
    border-radius: 10px;
    padding: 2%;
}

.profile-edit-modal-content h3 {
    font-weight: 600;
    font-size: 1.2em;
}

.profile-edit-modal-content p {
    font-weight: 400;
    font-size: 0.9em;
    margin-top: 10px;
    margin-bottom: 5px;
    color: rgba(0, 0, 0, 0.5);
}

.profile-edit-modal-content input {
    width: 100%;
    border: 1px solid #4B9ABD;
    border-radius: 5px;
    padding: 10px;
    font-size: 0.9em;
}

.profile-edit-modal-content textarea {
    width: 100%;
    border: 1px solid #4B9ABD;
    border-radius: 5px;
    padding: 10px;
    font-size: 0.9em;
    margin-top: 20px;
    resize: none;
}

.profile-edit-modal-content select {
    width: 100%;
    border: 1px solid #4B9ABD;
    border-radius: 5px;
    padding: 10px;
    font-size: 0.9em;
    cursor: pointer;
}

.profile-edit-modal-content input:focus, .profile-edit-modal-content textarea:focus {
    outline: 1px solid #4B9ABD;
}

.profile-edit-modal-content button {
    background-color: #4B9ABD;
    border: none;
    color: white;
    width: 100%;
    font-weight: 600;
    padding: 10px;
    border-radius: 5px;
    margin-top: 20px;
    cursor: pointer;
}

.error-text {
    color: red !important;
}

.edit-contact-navigate {
    display: flex;
    align-items: center;
}

.edit-contact-navigate h4 {
    position: relative;
    margin-top: 20px;
    font-weight: 500;
    font-size: 1em;
    margin-right: 20px;
    margin-bottom: 10px;
    cursor: pointer;
}

.selected-contact-nav {
    position: absolute;
    width: 100%;
    height: 3px;
    background-color: #4B9ABD;
    margin-top: 5px;
}

#edit-email-otp-btn, #edit-mobile-otp-btn {
    display: none;
}

.edit-contact-loading-btn {
    display: none;
}

.edit-contact-loading-btn i {
    font-size: 0.5em;
    margin-right: 3px;
    padding-left: 3px;
    cursor: default !important;
}

.edit-contact-loading-btn i:nth-child(1) {
    animation: loadingBtn 1s infinite;
}

.edit-contact-loading-btn i:nth-child(2) {
    animation: loadingBtn 1s infinite 0.2s;
}

.edit-contact-loading-btn i:nth-child(3) {
    animation: loadingBtn 1s infinite 0.4s;
}

@keyframes loadingBtn {
    0% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(-3px);
    }
    100% {
        transform: translateY(0px);
    }
}

@media (max-width: 768px) {
    .profile-edit-modal-content {
        width: 100%;
        border-radius: 10px 10px 0 0;
        padding: 5%;
        position: absolute;
        bottom: 0;
        max-height: 70%;
        overflow: scroll;
    }
}