.auth-modal {
    background: rgba(75, 154, 189, 0.3);
    backdrop-filter: blur(10px);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.auth-box {
    background-color: white;
    width: max(60%, 650px);
    height: max(60%, 550px);
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
}

.auth-left {
    width: 40%;
    background: rgba(28, 89, 129, 0.8);
    height: 100%;
    border-radius: 10px 0 0 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4%;
}

.auth-left h3 {
    text-transform: uppercase;
    color: white;
    font-weight: 800;
    font-size: 2em;
}

.auth-image {
    height: 45%;
    margin-top: 10%;
}

.auth-image img {
    height: 100%;
}

.auth-right {
    position: relative;
    width: 60%;
    padding: 4% 6%;
}

.auth-right h3 {
    text-align: center;
    text-transform: uppercase;
    color: #1C5981;
    font-weight: 800;
    font-size: 2em;
}

.auth-right h4 {
    text-align: center;
    color: #1C5981;
    font-weight: 500;
    font-size: 1.3em;
    margin-top: 10px;
    line-height: 1.5em;
}

.auth-input {
    margin-top: 20px;
}

.auth-input p {
    font-size: 0.8em;
    margin-bottom: 10px;
    margin-top: 10px;
}

.auth-input input {
    width: 100%;
    border: 1px solid #4B9ABD;
    padding: 8px 10px;
    border-radius: 5px;
    font-size: 0.9em;
    letter-spacing: 0.08em;
}

.auth-input input:focus {
    outline: 1px solid #4B9ABD;
}

.auth-input select {
    width: 100%;
    border: 1px solid #4B9ABD;
    padding: 8px 10px;
    border-radius: 5px;
    font-size: 0.9em;
    letter-spacing: 0.08em;
    cursor: pointer;
}

.auth-input select:focus {
    outline: 1px solid #4B9ABD;
}

.error-input {
    border: 1px solid red !important;
}

.error-input:focus {
    outline: 1px solid red !important;
}

.auth-error {
    color: red;
    margin-top: 10px !important;
    margin-bottom: 7px !important;
}

.auth-input span {
    font-size: 0.8em;
    color: rgba(0, 0, 0, 0.6);
}

.auth-input span a {
    color: #4B9ABD;
}

.auth-input button {
    width: 100%;
    margin-top: 10px;
    padding: 10px 10px;
    border: none;
    border-radius: 10px;
    background-color: #1C5981;
    color: white;
    font-weight: 500;
    cursor: pointer;
}

.loadingButton {
    display: none;
    cursor: default !important;
}

.auth-input button i {
    font-size: 0.5em;
    margin-right: 3px;
    padding-left: 3px;
}

.auth-input button i:nth-child(1) {
    animation: bounce 1s infinite;
}

.auth-input button i:nth-child(2) {
    animation: bounce 1s infinite 0.2s;
}

.auth-input button i:nth-child(3) {
    animation: bounce 1s infinite 0.4s;
}

.auth-footer {
    position: absolute;
    bottom: 20px;
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.auth-footer p {
    font-size: 0.8em;
    color: rgba(0, 0, 0, 0.6);
}

.auth-footer p a {
    color: #4B9ABD;
}

@media (max-width: 768px) {
    .auth-modal {
        display: block;
    }
    .auth-left {
        display: none;
    }
    .auth-box {
        position: absolute;
        bottom: 0;
        height: 60%;
        width: 100%;
        border-radius: 10px 10px 0 0;
    }
    .auth-right {
        width: 100%;
    }
    .auth-footer {
        width: 87%;
    }
}

@keyframes bounce {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
    100% {
        transform: translateY(0);
    }
}