.why-us {
    padding: 3%;
}

.why-us h2 {
    text-align: center;
    font-weight: 600;
    font-size: 1.8em;
}

.why-us-cards {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 5%;
}

.why-us-card {
    width: 17vw;
    height: 17vw;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    box-shadow: 0px 1.5px 7.5px rgba(0, 0, 0, 0.15);
    border-radius: 7.5px;
    margin: 20px;
}

.why-us-card img {
    width: 40%;
    margin-bottom: 10px;
}

.why-us-card p {
    text-align: center;
    font-size: 0.9em;
}

@media (max-width: 768px) {
    .why-us-cards {
        padding: 5% 0;
    }
    .why-us-card {
        width: 35vw;
        height: 35vw;
        margin: 3vw;
    }
    .why-us-card p {
        font-size: 3vw;
    }
}