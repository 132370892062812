.not-found {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: max(5%, 30px);
}

.not-found-image {
    width: max(40%, 300px);
}

.not-found-image img {
    width: 100%;
}

.not-found-info {
    text-align: center;
    margin-top: 40px;
}

.not-found-info h3 {
    color: #10405D;
    font-style: italic;
    font-size: 1.5rem;
    font-weight: 600;
}

.not-found-info p {
    color: rgba(0, 0, 0, 0.7);
    font-style: italic;
    margin-top: 10px;
    line-height: 150%;
    margin-bottom: 30px;
}

.not-found-info a {
    background-color: #4B9ABD;
    padding: 10px 20px;
    border-radius: 5px;
    color: white;
    font-weight: 500;
    text-decoration: none;
}

@media (max-width: 768px) {
    .not-found {
        padding-top: 100px;
        padding-bottom: 50px;
    }
}